



































import AppComponent from '@/mixins/ComponentMixin.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class AddContentOrCreate extends AppComponent {
  @Prop({ required: true, type: String, default: 'Element'}) public label: string;
  @Prop({ required: true, type: Array, default: []}) public value: string [];
  @Prop({ required: true, type: Array, default: []}) public list: string;

  public selectedInternal: string[] = [];
  public search: string = '';


  get selected() {
    return this.cloneList(this.value);
  }

  set selected(newVal) {
    this.selectedInternal = this.cloneList(newVal);
  }

  @Watch('selectedInternal')
  public onSelectedChanged() {
      this.$emit('input', this.selectedInternal);
  }

}


