

















































import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICreateFood, IFood, IUpdateFood } from '@/interfaces/foods';
import { dispatchGetFoodsById, dispatchUpdateFood } from '@/store/crud/actions';

@Component({})
export default class FoodForm extends AppComponent {
  @Prop({ type: String, required: false, default: null }) public foodId: string;

  public valid = false;
  /*
    food data
  */
  public name: string = '';
  public foodGroup: string = '';
  public description: string = '';
  public food: IFood | null = null;
  public forceCreation: boolean = false;
  public busId: number = 0;


  public async initiView() {
    if (!this.isCreation && this.foodId && this.foodId.length > 0) {
      this.food = await dispatchGetFoodsById(this.$store, this.foodId);
      if (!this.food || Object.keys(this.food).length === 0) {
        this.forceCreation = true; // force creation if food is not found or error with the API
      }
    }
    this.reset();
  }

  /**
   * Resets the form.
   * if the food id is provided, this will be an edit form so existing data is injected into the form
   * otherwise, this will be a creation form and the data will be empty
   */
  public reset() {
    if (this.isCreation) {
      this.name = '';
      this.foodGroup = '';
      this.description = '';
    } else {
      if (this.food) {
        this.name = this.food.name || '';
        this.foodGroup = this.food.foodGroup || '';
        this.description = this.food.description || '';
      } else {
        // weird  case, we just force creation and re-call the function
        this.forceCreation = true;
        this.reset();
        return;
      }
    }
    this.$validator.reset();
  }

  public cancel() {
    this.$emit('food:cancelled');
  }

  public get isCreation() {
    return this.foodId === null || this.forceCreation;
  }

  public async mounted() {
    this.initiView();
    this.busId = this.$bus.$on('food:reset', this.initiView);
  }

  public beforeDestroy() {
    this.$bus.$off('food:reset', this.busId);
  }


  /**
   * Updates the food
   * Checks if the form variables are valid / available
   * and only sends the data if it changed from the original data
   * @Argument {boolean} silent - false by default, if true it emits an
   * food:updated event to notify parent components that a new food has been
   * created
   */
  public async updateFood(silent = false) {
    const uploadPayload: IUpdateFood = {};
    if (this.food && this.food.id) {
      this.setFieldIfExistAndValueChanged(uploadPayload, 'name', this.name, this.food?.name);
      this.setFieldIfChanges(uploadPayload, 'foodGroup', this.foodGroup,
        this.food?.foodGroup);
      this.setFieldIfChanges(uploadPayload, 'description', this.description, this.food?.description);
      if (Object.keys(uploadPayload).length > 0) {
        const result = await dispatchUpdateFood(
          this.$store, { foodId: this.foodId, food: uploadPayload });
        if (result) {
          if (!silent) {
            this.$emit('food:updated', result);
          }
        } else {
          if (!silent) {
            this.toast('Error while updating food', true);
          }
        }
      }
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.isCreation) {
        const protoFood: IFood = {
          name: this.name,
          foodGroup: this.foodGroup,
          description: this.description,
        };
        // sanitize creation data
        if (!this.hasValue(protoFood.name)) {
          this.toast('Name is required', true); // name is required
          return;
        }

        if (!this.hasValue(protoFood.foodGroup)) { delete protoFood.foodGroup; }
        if (!this.hasValue(protoFood.description)) { delete protoFood.description; }

        const createFood: ICreateFood = protoFood as ICreateFood;
        this.$emit('food:created', createFood);
      } else {
        this.updateFood();
      }
    }
  }
}
