





















/**
 * @Author mcastrucci
 * Create Symptom Modal.
 * This modal contains the Create symptom form. It should be used when creating a new symptom
 * on the flight without changing the current view as a Modal.
 */
import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import SymptomForm from '@/components/forms/SymptomForm.vue';

@Component({ components: { SymptomForm } })
export default class SymptomFormModal extends AppComponent {
  @Prop({ type: Boolean, default: false }) public isEdit: boolean;

  public dialog = false;
  public busEventId = 0;
  public symptomId = '';

  public created() {
    if (this.isEdit) {
      /*  only list for edit events if this is an edit modal */
      this.busEventId = this.$bus.$on('symptom:edit', this.editSymptom);
    }
  }

  public beforeDestroy() {
    if (this.isEdit) {
      this.$bus.$off('symptom:edit', this.busEventId);
    }
  }

  /**
   * Event handler for edition.
   * Sets the id to trigger the form (child element in template) to change
   */
  public async editSymptom(symptomId: string) {
      this.symptomId = symptomId;
      await this.$nextTick();
      this.$bus.$emit('symptom:reset');
      this.dialog = true;
  }

  /**
   * Re-emits a creation event to the parent component
   */
  public async symptomCreated(result) {
    this.$emit('symptom:created', result);
    this.dialog = false;
    await this.$nextTick();
    this.$bus.$emit('symptom:reset');
  }

  /**
   * Re-emits an update event to the parent component
   */
  public symptomUpdated(result) {
    this.$emit('symptom:updated', result);
    this.dialog = false;
  }

  public get isCreation() {
    return !this.symptomId;
  }

  public cancel() {
    this.dialog = false;
    this.$bus.$emit('symptom:reset');
  }

}
