









































































import { Component, Prop } from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ISelfCare } from '@/interfaces/selfCare';

@Component({ components: { ConfirmationModal } })
export default class SelfCareTable extends AppComponent {
@Prop({ default: [] }) public value: ISelfCare[];
@Prop({ default: true }) public showActions: boolean;
@Prop({ default: true }) public showSearch: boolean;
@Prop({ default: false }) public yieldActions: boolean;
@Prop({ default: 10 }) public itemsPerPage: number;

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search =  '';

  /**
   * Edit the selected selfCare from the list
   * If the yieldActions prop is true, emit the id of the selfCare to be edited will
   * be went to the parent component by a 'edit' event
   * otherwise, the user will be redirected to the edit selfCare page
   * @param id the id of the selfCare to be edited
   */
  public editSelfCare(selfCareId: string) {
    if (this.yieldActions) {
      this.$emit('selfcare:edited', selfCareId);
    } else {
      this.$router.push({
        name: 'main-content-selfcare-edit',
        params: { selfCareId },
      });
    }
  }

  /**
   * Delete a selfCare and refresh the screen
   */
  public async deleteSelfCare(selfCareId: string) {
    this.$emit('selfcare:deleted', selfCareId);
  }
}
