




































































import { Component, Prop } from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IExercise } from '@/interfaces/exercises';

@Component({ components: { ConfirmationModal } })
export default class FoodsTable extends AppComponent {
@Prop({ default: [] }) public value: IExercise[];
@Prop({ default: true }) public showActions: boolean;
@Prop({ default: true }) public showSearch: boolean;
@Prop({ default: false }) public yieldActions: boolean;
@Prop({ default: 10 }) public itemsPerPage: number;

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Food Group',
      sortable: true,
      value: 'foodGroup',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search =  '';

  /**
   * Edit the selected Food from the list
   * If the yieldActions prop is true, emit the id of the food to be edited will
   * be went to the parent component by a 'edit' event
   * otherwise, the user will be redirected to the edit food page
   * @param id the id of the food to be edited
   */
  public editFood(foodId: string) {
    if (this.yieldActions) {
      this.$emit('foods:edited', foodId);
    } else {
      this.$router.push({
        name: 'main-content-foods-edit',
        params: { foodId },
      });
    }
  }

  /**
   * Delete a foods and refresh the screen
   */
  public async deleteFood(foodId: string) {
    this.$emit('foods:deleted', foodId);
  }
}
