





















/**
 * @Author mcastrucci
 * Create / Edit food modal
 * This modal contains the food form. It should be used when it is needed to
 * create a food on the fly
 */
import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import FoodForm from '@/components/forms/FoodForm.vue';

@Component({ components: { FoodForm } })
export default class FoodFormModal extends AppComponent {
  @Prop({ type: Boolean, default: false }) public isEdit: boolean;

  public dialog = false;
  public busEventId = 0;
  public foodId = '';

  public created() {
    if (this.isEdit) {
      /*  only list for edit events if this is an edit modal */
      this.busEventId = this.$bus.$on('food:edit', this.editFood);
    }
  }

  public beforeDestroy() {
    if (this.isEdit) {
      this.$bus.$off('food:edit', this.busEventId);
    }
  }

  /**
   * Event handler for edition.
   * Sets the foodEdit to trigger the form to charge food data
   * and show the dialog
   */
  public async editFood(foodId: string) {
      this.foodId = foodId;
      await this.$nextTick();
      this.$bus.$emit('food:reset');
      this.dialog = true;
  }

  /**
   * Re-emits a creation event to the parent component
   */
  public async foodCreated(result) {
    this.$emit('food:created', result);
    this.dialog = false;
    await this.$nextTick();
    this.$bus.$emit('food:reset');
  }

  /**
   * Re-emits an update event to the parent component
   */
  public foodUpdated(result) {
    this.$emit('food:updated', result);
    this.dialog = false;
  }

  public get isCreation() {
    return !this.foodId;
  }

  public cancel() {
    this.dialog = false;
    this.$bus.$emit('food:reset');
  }

}
