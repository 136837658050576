





















/**
 * @Author mcastrucci
 * Create Cause Modal.
 * This modal contains the Create cause form. It should be used when creating a new cause
 * on the flight without changing the current view as a Modal.
 */
import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import CauseForm from '@/components/forms/CauseForm.vue';

@Component({ components: { CauseForm } })
export default class CauseFormModal extends AppComponent {
  @Prop({ type: Boolean, default: false }) public isEdit: boolean;

  public dialog = false;
  public busEventId = 0;
  public causeId = '';

  public created() {
    if (this.isEdit) {
      /*  only list for edit events if this is an edit modal */
      this.busEventId = this.$bus.$on('cause:edit', this.editCause);
    }
  }

  public beforeDestroy() {
    if (this.isEdit) {
      this.$bus.$off('cause:edit', this.busEventId);
    }
  }

  /**
   * Event handler for edition.
   * Sets the id to trigger the form (child element in template) to change
   */
  public async editCause(causeId: string) {
      this.causeId = causeId;
      await this.$nextTick();
      this.$bus.$emit('cause:reset');
      this.dialog = true;
  }

  /**
   * Re-emits a creation event to the parent component
   */
  public async causeCreated(result) {
    this.$emit('cause:created', result);
    this.dialog = false;
    await this.$nextTick();
    this.$bus.$emit('cause:reset');
  }

  /**
   * Re-emits an update event to the parent component
   */
  public causeUpdated(result) {
    this.$emit('cause:updated', result);
    this.dialog = false;
  }

  public get isCreation() {
    return !this.causeId;
  }

  public cancel() {
    this.dialog = false;
    this.$bus.$emit('cause:reset');
  }

}
