









































































import { Component, Prop } from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IExercise } from '@/interfaces/exercises';

@Component({ components: { ConfirmationModal } })
export default class ExerciseTable extends AppComponent {
@Prop({ default: [] }) public value: IExercise[];
@Prop({ default: true }) public showActions: boolean;
@Prop({ default: true }) public showSearch: boolean;
@Prop({ default: false }) public yieldActions: boolean;
@Prop({ default: 10 }) public itemsPerPage: number;

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Exercise Type',
      sortable: true,
      value: 'exerciseType',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search =  '';

  /**
   * Edit the selected exercise from the list
   * If the yieldActions prop is true, emit the id of the exercise to be edited will
   * be went to the parent component by a 'edit' event
   * otherwise, the user will be redirected to the edit exercise page
   * @param id the id of the exercise to be edited
   */
  public editExercise(exerciseId: string) {
    if (this.yieldActions) {
      this.$emit('exercises:edited', exerciseId);
    } else {
      this.$router.push({
        name: 'main-content-exercises-edit',
        params: { exerciseId },
      });
    }
  }

  /**
   * Delete a exercise and refresh the screen
   */
  public async deleteExercise(exerciseId: string) {
    this.$emit('exercises:deleted', exerciseId);
  }
}
