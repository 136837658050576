





















/**
 * @Author mcastrucci
 * Create exercise result Modal.
 * This modal contains the BioamrkerResult form. It should be used when creating a new exercise result
 * in the flight without changing the current view as a Modal.
 */
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import ExerciseForm from '@/components/forms/ExerciseForm.vue';

@Component({ components: { ExerciseForm } })
export default class ExerciseFormModal extends AppComponent {
  @Prop({ type: Boolean, default: false }) public isEdit: boolean;

  public dialog = false;
  public busEventId = 0;
  public exerciseId = '';

  public created() {
    if (this.isEdit) {
      /*  only list for edit events if this is an edit modal */
      this.busEventId = this.$bus.$on('exercise:edit', this.editExercise);
    }
  }

  public beforeDestroy() {
    if (this.isEdit) {
      this.$bus.$off('exercise:edit', this.busEventId);
    }
  }

  /**
   * Event handler for edition.
   * Sets the exerciseEdit to trigger the form to charge exercise data
   * and show the dialog
   */
  public async editExercise(exerciseId: string) {
      this.exerciseId = exerciseId;
      await this.$nextTick();
      this.$bus.$emit('exercise:reset');
      this.dialog = true;
  }

  /**
   * Re-emits a creation event to the parent component
   */
  public async exerciseCreated(result) {
    this.$emit('exercise:created', result);
    this.dialog = false;
    await this.$nextTick();
    this.$bus.$emit('exercise:reset');
  }

  /**
   * Re-emits an update event to the parent component
   */
  public exerciseUpdated(result) {
    this.$emit('exercise:updated', result);
    this.dialog = false;
  }

  public get isCreation() {
    return !this.exerciseId;
  }

  public cancel() {
    this.dialog = false;
    this.$bus.$emit('exercise:reset');
  }

}
