




































































import { Component, Prop } from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ISupplement } from '@/interfaces/supplements';

@Component({ components: { ConfirmationModal } })
export default class SupplementsTable extends AppComponent {
@Prop({ default: [] }) public value: ISupplement[];
@Prop({ default: true }) public showActions: boolean;
@Prop({ default: true }) public showSearch: boolean;
@Prop({ default: false }) public yieldActions: boolean;
@Prop({ default: 10 }) public itemsPerPage: number;

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Supplement Group',
      sortable: true,
      value: 'supplementGroup',
      align: 'left',
    },
    {
      text: 'description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search =  '';

  /**
   * Edit the selected supplement from the list
   * If the yieldActions prop is true, emit the id of the supplement to be edited will
   * be went to the parent component by a 'edit' event
   * otherwise, the user will be redirected to the edit supplement page
   * @param id the id of the supplement to be edited
   */
  public editSupplement(supplementId: string) {
    if (this.yieldActions) {
      this.$emit('supplements:edited', supplementId);
    } else {
      this.$router.push({
        name: 'main-content-supplements-edit',
        params: { supplementId },
      });
    }
  }

  /**
   * Delete a supplements and refresh the screen
   */
  public async deleteSupplement(supplementId: string) {
    this.$emit('supplements:deleted', supplementId);
  }
}
