





















/**
 * @Author mcastrucci
 * Create / Edit Supplement modal
 * This modal contains the Supplement form. It should be used when it is needed to
 * create a supplement on the fly
 */
import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import SupplementForm from '@/components/forms/SupplementForm.vue';

@Component({ components: { SupplementForm } })
export default class SupplementFormModal extends AppComponent {
  @Prop({ type: Boolean, default: false }) public isEdit: boolean;

  public dialog = false;
  public busEventId = 0;
  public supplementId = '';

  public created() {
    if (this.isEdit) {
      /*  only list for edit events if this is an edit modal */
      this.busEventId = this.$bus.$on('supplement:edit', this.editSupplement);
    }
  }

  public beforeDestroy() {
    if (this.isEdit) {
      this.$bus.$off('supplement:edit', this.busEventId);
    }
  }

  /**
   * Event handler for edition.
   * Sets the supplementEdit to trigger the form to charge supplement data
   * and show the dialog
   */
  public async editSupplement(supplementId: string) {
      this.supplementId = supplementId;
      await this.$nextTick();
      this.$bus.$emit('supplement:reset');
      this.dialog = true;
  }

  /**
   * Re-emits a creation event to the parent component
   */
  public async supplementCreated(result) {
    this.$emit('supplement:created', result);
    this.dialog = false;
    await this.$nextTick();
    this.$bus.$emit('supplement:reset');
  }

  /**
   * Re-emits an update event to the parent component
   */
  public supplementUpdated(result) {
    this.$emit('supplement:updated', result);
    this.dialog = false;
  }

  public get isCreation() {
    return !this.supplementId;
  }

  public cancel() {
    this.dialog = false;
    this.$bus.$emit('supplement:reset');
  }

}
