




import RecommendationForm from '@/components/forms/RecommendationForm.vue';
import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICreateRecommendation, IUpdateRecommendation } from '@/interfaces/recommendations';
import { dispatchCreateRecommendation } from '@/store/crud/actions';


@Component({ components: { RecommendationForm } })
export default class Recommendation extends AppComponent {
  @Prop({ type: String }) public recommendationId: string;

  public async recommendationCreated(recommendation: ICreateRecommendation) {
    if (recommendation && Object.keys(recommendation).length > 0) {
      const result = await dispatchCreateRecommendation(this.$store, recommendation);
      if (result) {
        this.$router.push('/main/content/recommendations');
      } else {
        // FIXME important log here
      }
    }
  }

  public recommendationUpdated(recommendation: IUpdateRecommendation) {
    this.$router.push('/main/content/recommendations');
  }


  public cancel() {
    this.$router.push('/main/content/recommendations');
  }
}
