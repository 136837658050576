

















































import { Component, Prop } from 'vue-property-decorator';
import { dispatchGetSupplementsById, dispatchUpdateSupplement } from '@/store/crud/actions';
import { ICreateSupplement, ISupplement, IUpdateSupplement } from '@/interfaces/supplements';
import AppComponent from '@/mixins/ComponentMixin.vue';

@Component({})
export default class SupplementForm extends AppComponent {
  @Prop({ type: String, required: false, default: null }) public supplementId: string;

  public valid = false;
  /*
    supplement data
  */
  public name: string = '';
  public supplementGroup: string = '';
  public description: string = '';
  public supplement: ISupplement | null = null;
  public forceCreation: boolean = false;
  public busId: number = 0;


  public async initiView() {
    if (!this.isCreation && this.supplementId && this.supplementId.length > 0) {
      this.supplement = await dispatchGetSupplementsById(this.$store, this.supplementId);
      if (!this.supplement || Object.keys(this.supplement).length === 0) {
        this.forceCreation = true; // force creation if supplement is not found or error with the API
      }
    }
    this.reset();
  }

  /**
   * Resets the form.
   * if the supplement id is provided, this will be an edit form so existing data is injected into the form
   * otherwise, this will be a creation form and the data will be empty
   */
  public reset() {
    if (this.isCreation) {
      this.name = '';
      this.supplementGroup = '';
      this.description = '';
    } else {
      if (this.supplement) {
        this.name = this.supplement.name || '';
        this.supplementGroup = this.supplement.supplementGroup || '';
        this.description = this.supplement.description || '';
      } else {
        // weird  case, we just force creation and re-call the function
        this.forceCreation = true;
        this.reset();
        return;
      }
    }
    this.$validator.reset();
  }

  public cancel() {
    this.$emit('suppelement:cancelled');
  }

  public get isCreation() {
    return this.supplementId === null || this.forceCreation;
  }

  public async mounted() {
    this.initiView();
    this.busId = this.$bus.$on('food:reset', this.initiView);
  }

  public beforeDestroy() {
    this.$bus.$off('food:reset', this.busId);
  }


  /**
   * Updates the supplement
   * Checks if the form variables are valid / available
   * and only sends the data if it changed from the original data
   * @Argument {boolean} silent - false by default, if true it emits an
   * supplement:updated event to notify parent components that a new supplement has been
   * created
   */
  public async updateSupplement(silent = false) {
    const uploadPayload: IUpdateSupplement = {};
    if (this.supplement && this.supplement.id) {
      this.setFieldIfChanges(uploadPayload, 'name', this.name, this.supplement.name);
      this.setFieldIfChanges(uploadPayload, 'supplementGroup', this.supplementGroup, this.supplement.supplementGroup);
      this.setFieldIfChanges(uploadPayload, 'description', this.description, this.supplement.description);
      if (Object.keys(uploadPayload).length > 0) {
        const result = await dispatchUpdateSupplement(
          this.$store, { supplementId: this.supplement.id, supplement: uploadPayload });
        if (result) {
          if (!silent) {
            this.$emit('supplement:updated', result);
          }
        } else {
          if (!silent) {
            this.toast('Error while updating supplement', true);
          }
        }
      }
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.isCreation) {
        const protoSupplement: ISupplement = {
          name: this.name,
          supplementGroup: this.supplementGroup,
          description: this.description,
        };
        // sanitize creation data
        if (!this.hasValue(protoSupplement.name)) {
          this.toast('Name is required', true); // name is required
          return;
        }

        if (!this.hasValue(protoSupplement.supplementGroup)) { delete protoSupplement.supplementGroup; }
        if (!this.hasValue(protoSupplement.description)) { delete protoSupplement.description; }

        const createSupplement: ICreateSupplement = protoSupplement as ICreateSupplement;
        this.$emit('supplement:created', createSupplement);
      } else {
        this.updateSupplement();
      }
    }
  }
}
