





















/**
 * @Author mcastrucci
 * Create SelfCare Modal.
 * This modal contains the Create selfCare form. It should be used when creating a new selfCare
 * on the flight without changing the current view as a Modal.
 */
import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import SelfCareForm from '@/components/forms/SelfCareForm.vue';

@Component({ components: { SelfCareForm } })
export default class SelfCareFormModal extends AppComponent {
  @Prop({ type: Boolean, default: false }) public isEdit: boolean;

  public dialog = false;
  public busEventId = 0;
  public selfCareId = '';

  public created() {
    if (this.isEdit) {
      /*  only list for edit events if this is an edit modal */
      this.busEventId = this.$bus.$on('selfcare:edit', this.editSelfCare);
    }
  }

  public beforeDestroy() {
    if (this.isEdit) {
      this.$bus.$off('selfcare:edit', this.busEventId);
    }
  }

  /**
   * Event handler for edition.
   * Sets the id to trigger the form (child element in template) to change
   */
  public async editSelfCare(selfCareId: string) {
      this.selfCareId = selfCareId;
      await this.$nextTick();
      this.$bus.$emit('selfcare:reset');
      this.dialog = true;
  }

  /**
   * Re-emits a creation event to the parent component
   */
  public async selfCareCreated(result) {
    this.$emit('selfcare:created', result);
    this.dialog = false;
    await this.$nextTick();
    this.$bus.$emit('selfcare:reset');
  }

  /**
   * Re-emits an update event to the parent component
   */
  public selfCareUpdated(result) {
    this.$emit('selfcare:updated', result);
    this.dialog = false;
  }

  public get isCreation() {
    return !this.selfCareId;
  }

  public cancel() {
    this.dialog = false;
    this.$bus.$emit('selfcare:reset');
  }

}
