








































































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {
  dispatchCreateCause,
  dispatchCreateExercise,
  dispatchCreateFood,
  dispatchCreateSelfCare,
  dispatchCreateSupplement,
  dispatchCreateSymptom,
  dispatchDeleteCause,
  dispatchDeleteExercise,
  dispatchDeleteFood,
  dispatchDeleteSelfCare,
  dispatchDeleteSupplement,
  dispatchDeleteSymptom,
  dispatchGetBiomarkersForList,
  dispatchGetCauseById,
  dispatchGetCausesForList,
  dispatchGetExerciseById,
  dispatchGetExercisesForList,
  dispatchGetFoodsById,
  dispatchGetFoodsForList,
  dispatchGetPanelsForList,
  dispatchGetRecommendationById,
  dispatchGetSelfCareById,
  dispatchGetSelfCaresForList,
  dispatchGetSupplementsById,
  dispatchGetSupplementsForList,
  dispatchGetSymptomById,
  dispatchGetSymptomsForList,
  dispatchUpdateRecommendation,
} from '@/store/crud/actions';
import { IBiomarker } from '@/interfaces/biomarkers';
import {
  readOneBiomarker,
  readOneCause,
  readOneExercise,
  readOneFood,
  readOneSelfCare,
  readOneSupplement,
  readOneSymptom,
  } from '@/store/crud/getters';
import { ICreateExercise, IExercise } from '@/interfaces/exercises';
import { ICreateSupplement, ISupplement } from '@/interfaces/supplements';
import { ICreateFood, IFood } from '@/interfaces/foods';

/* Tables */
import ExerciseTable from '@/components/tables/ExerciseTable.vue';
import FoodsTable from '@/components/tables/FoodsTable.vue';
import SupplementsTable from '@/components/tables/SupplementsTable.vue';
import SymptomsTable from '@/components/tables/SymptomsTable.vue';
import CausesTable from '@/components/tables/CausesTable.vue';
import SelfCaresTable from '@/components/tables/SelfCaresTable.vue';

/* Modals*/
import ExerciseFormModal from '@/components/modals/ExerciseFormModal.vue';
import FoodFormModal from '@/components/modals/FoodFormModal.vue';
import SupplementFormModal from '@/components/modals/SupplementFormModal.vue';
import SymptomFormModal from '@/components/modals/SymptomFormModal.vue';
import CauseFormModal from '@/components/modals/CauseFormModal.vue';
import SelfCareFormModal from '@/components/modals/SelfCareFormModal.vue';


import AddContentOrCreate from '@/components/AddContentOrCreate.vue';
import { ICreateRecommendation, IRecommendation, IUpdateRecommendation } from '@/interfaces/recommendations';
import { outOfRangeTypes } from '@/constants/listOptions';
import { ICause, ICreateCause } from '@/interfaces/causes';
import { ICreateSymptom, ISymptom } from '@/interfaces/symptoms';
import { ICreateSelfCare, ISelfCare } from '@/interfaces/selfCare';
import { biomarkerSexOptions } from '@/constants/listOptions';

@Component({ components: {
  DataPicker,
  ExerciseTable,
  ExerciseFormModal,
  FoodsTable,
  FoodFormModal,
  SupplementsTable,
  SupplementFormModal,
  AddContentOrCreate,
  SymptomsTable,
  SymptomFormModal,
  CausesTable,
  CauseFormModal,
  SelfCareFormModal,
  SelfCaresTable,
  },
})
export default class RecommendationForm extends AppComponent {
  @Prop({ required: false, type: Array }) public biomarker: IBiomarker;
  @Prop({ required: false, type: String }) public recommendationId: string;

  public biomarkerSexOptions = biomarkerSexOptions;

  public assignedBiomarker: string = '';
  public selectedExercises: string[] = [];
  public selectedFoods: string[] = [];
  public selectedFoodsToAvoid: string[] = [];
  public selectedCauses: string[] = [];
  public selectedSymptoms: string[] = [];
  public selectedSupplements: string[] = [];
  public selectedSelfCares: string[] = [];
  public selectedAdditionalTests: string[] = [];


  public valid: boolean = false;
  public name: string = '';
  public froceCration: boolean = false;

  public listOfExercises: IExercise[] = [];
  public listOfSupplements: ISupplement[] = [];
  public listOfFoods: IFood[] = [];
  public listOfFoodsToAvoid: IFood[] = [];
  public listOfCauses: ICause[] = [];
  public listOfSymptoms: ISymptom[] = [];
  public listOfSelfCares: ISelfCare[] = [];


  public inRange: boolean = false;
  public outOfRangeType: string = '';
  public outOfRangeTypes = outOfRangeTypes;

  public summaryDescription: string = '';
  public followUpsDescription: string = '';
  public causesDescription: string = '';
  public symptomsDescription: string = '';
  public foodsToEatDescription: string = '';
  public foodsToAvoidDescription: string = '';
  public supplementsDescription: string = '';
  public selfCareDescription: string = '';
  public additionalTestsDescription: string = '';

  public sex: string = '';


  public recommendation: IRecommendation | null = null;

  public async initView() {
    this.setAppLoading(true);
    await this.fetchData();
    this.reset();
    this.setAppLoading(false);
  }

  /**
   * Component starting point
   */
  public async mounted() {
    this.initView();
  }

  public get isCreation() {
    return !this.recommendationId || this.froceCration;
  }

  public get isCreationFromBiomarker() {
    return this.biomarker && this.biomarker.id;
  }

  /**
   * it reset the form with default values
   */
  public reset() {
    if (this.isCreation) {
      this.assignedBiomarker = this.biomarker && this.biomarker.id ? this.biomarker.id : '';
      this.selectedExercises = [];
      this.selectedAdditionalTests = [];
      this.selectedFoods = [];
      this.selectedFoodsToAvoid = [];
      this.selectedSupplements = [];
      this.selectedCauses = [];
      this.selectedSymptoms = [];
      this.selectedSelfCares = [];
      this.sex = 'All';
      this.causesDescription = '';
      this.summaryDescription = '';
      this.followUpsDescription = '';
      this.symptomsDescription = '';
      this.foodsToEatDescription = '';
      this.foodsToAvoidDescription = '';
      this.supplementsDescription = '';
      this.selfCareDescription = '';
      this.additionalTestsDescription = '';
      this.name = '';
      this.inRange = false;
      this.outOfRangeType = '';
    } else {
      if (this.recommendation) {
        this.assignedBiomarker = this.recommendation.biomarkerId ? this.recommendation.biomarkerId : '';
        this.inRange = this.recommendation.inRange;
        this.outOfRangeType = this.recommendation.outOfRangeType || '';
        this.summaryDescription = this.recommendation.summaryDescription || '';
        this.causesDescription = this.recommendation.causesDescription || '';
        this.sex = this.recommendation.sex || 'All';
        this.symptomsDescription = this.recommendation.symptomsDescription || '';
        this.foodsToEatDescription = this.recommendation.foodsToEatDescription || '';
        this.foodsToAvoidDescription = this.recommendation.foodsToAvoidDescription || '';
        this.supplementsDescription = this.recommendation.supplementsDescription || '';
        this.selfCareDescription = this.recommendation.selfCareDescription || '';
        this.followUpsDescription = this.recommendation.followUpsDescription || '';
        this.additionalTestsDescription = this.recommendation.additionalTestsDescription || '';
        this.selectedExercises = this.recommendation.exercises && this.recommendation.exercises.length > 0 ?
          this.extractIds(this.recommendation.exercises) : [];
        this.selectedFoods = this.recommendation.foodsToEat && this.recommendation.foodsToEat.length > 0 ?
          this.extractIds(this.recommendation.foodsToEat) : [];
        this.selectedSupplements = this.recommendation.supplements && this.recommendation.supplements.length > 0 ?
          this.extractIds(this.recommendation.supplements) : [];
        this.selectedFoodsToAvoid = this.recommendation.foodsToAvoid && this.recommendation.foodsToAvoid.length > 0 ?
          this.extractIds(this.recommendation.foodsToAvoid) : [];
        this.selectedCauses = this.recommendation.causes && this.recommendation.causes.length > 0 ?
          this.extractIds(this.recommendation.causes) : [];
        this.selectedSymptoms = this.recommendation.symptoms && this.recommendation.symptoms.length > 0 ?
          this.extractIds(this.recommendation.symptoms) : [];
        this.selectedSelfCares = this.recommendation.selfcares && this.recommendation.selfcares.length > 0 ?
          this.extractIds(this.recommendation.selfcares) : [];
        this.selectedAdditionalTests = this.recommendation.additionalTests &&
            this.recommendation.additionalTests.length > 0 ? this.extractIds(this.recommendation.additionalTests) : [];
      }
    }
    this.$validator.reset();
  }

  /**
   * fetchs data asyncronously
   */
  public async fetchData() {
    await dispatchGetBiomarkersForList(this.$store);
    await dispatchGetFoodsForList(this.$store);
    await dispatchGetExercisesForList(this.$store);
    await dispatchGetSupplementsForList(this.$store);
    await dispatchGetSymptomsForList(this.$store);
    await dispatchGetCausesForList(this.$store);
    await dispatchGetSelfCaresForList(this.$store);
    await dispatchGetPanelsForList(this.$store);
    if (this.recommendationId) {
      const result = await dispatchGetRecommendationById(this.$store, this.recommendationId);
      if (result) {
        this.recommendation = result;
      } else {
        // Something failed while getting the recommendation from database
        // FIXME - important log here
        // continue as creation
      }
    }
  }

  /**
   * Generic listChanged handler
   * It process the list of selected items ids and map them into an actual list of items with their content
   * @param selectedsList - list name to be extracted from the Vue instance, necessary to make the function generic
   * @param getterFunction - a getter function from Vuex store to extract the item data
   * @param actionFunction - A Vuex action to execute if the item does not have
   * the complete content (for example, if it is only id/name)
   */
  public selectedChangedHandler(selectedsList: string, listName: string, getterFunction: (vuexStore) => any,
                                actionFunction: (vuexStore, payload) => any ) {
    const items: any [] = [];
    if (this[selectedsList] && Array.isArray(this[selectedsList])) {

      this[selectedsList].forEach(async (id) => {
        const currentItem = getterFunction(this.$store)(id);
        if (currentItem) {
          // check if data is complete
          if (Object.keys(currentItem).length < 4) { // normal will be id and name and typename
            try {
              const upgradedItem = await actionFunction(this.$store, currentItem.id);

              if (upgradedItem) {
                items.push(upgradedItem);
                return;
              }
            } catch { /* ignore the exception the page will be lacking some information but is not critical */ }
          }
          items.push(currentItem);
        }
      });
    }

    this[listName] = items;
  }

  /**
   * Builds the recommendation name based on the content of the recommendation
   * form
   */
  public buildRecommendationName() {
    let recommendationName: string = '';
    if (this.assignedBiomarker) {
      const biomarker = readOneBiomarker(this.$store)(this.assignedBiomarker);
      if (biomarker) {
        recommendationName = biomarker.name ?? '';

        if (this.inRange) {
          recommendationName += ' in range';
        } else {
          recommendationName += ' out of range';
          if (this.outOfRangeType) {
            recommendationName += ' - ' + this.outOfRangeType;
          }
        }

        if (this.sex) {
          recommendationName += ' for ' + this.sex;
        }
      }
    }
    this.name = recommendationName;
  }

  @Watch('inRange')
  public onInRangeChange() {
    if (this.inRange) {
      this.outOfRangeType = '';
    }
    this.buildRecommendationName();
  }

  @Watch('sex')
  public onSexChange() {
    this.buildRecommendationName();
  }

  @Watch('outOfRangeType')
  public onOutOfRangeTypeChange() {
    this.buildRecommendationName();
  }

  @Watch('assignedBiomarker')
  public onAssignedBiomarkerChange() {
    this.buildRecommendationName();
  }

  @Watch('selectedExercises')
  public onSelectedExercisesChanged() {
    this.selectedChangedHandler('selectedExercises', 'listOfExercises', readOneExercise, dispatchGetExerciseById);
  }

  @Watch('selectedCauses')
  public onSelectedCausesChanged() {
    this.selectedChangedHandler('selectedCauses', 'listOfCauses', readOneCause, dispatchGetCauseById);
  }

  @Watch('selectedSymptoms')
  public onSelectedSymptomsChanged() {
    this.selectedChangedHandler('selectedSymptoms', 'listOfSymptoms', readOneSymptom, dispatchGetSymptomById);
  }

  @Watch('selectedFoods')
  public onSelectedFoodsChanged() {
    this.selectedChangedHandler('selectedFoods', 'listOfFoods', readOneFood, dispatchGetFoodsById);
  }

  @Watch('selectedFoodsToAvoid')
  public onSelectedFoodsToAvoidChanged() {
    this.selectedChangedHandler('selectedFoodsToAvoid', 'listOfFoodsToAvoid', readOneFood, dispatchGetFoodsById);
  }

  @Watch('selectedSupplements')
  public onSelectedSupplementsChanged() {
      this.selectedChangedHandler('selectedSupplements', 'listOfSupplements',
        readOneSupplement, dispatchGetSupplementsById);
  }

  @Watch('selectedSelfCares')
  public onSelectedSelfCaresChanged() {
      this.selectedChangedHandler('selectedSelfCares', 'listOfSelfCares', readOneSelfCare, dispatchGetSelfCareById);
  }

  /**
   * event handler for selfCare creation
   * creates a selfCare and includes it into the list of
   * selected selfCares
   * @Args selfCare: ISelfCare
   */
  public async selfCareCreated(selfCare: ISelfCare) {
    const result = await dispatchCreateSelfCare(this.$store, selfCare as ICreateSelfCare);
    if (result && result.id) {
      this.selectedSelfCares.push(result.id);
    } else {
      // FIXME - important log here
    }
  }

  /**
   * Deletes a selfCare from the Database
   * and from the list of selected selfCares
   */
  public async selfCareDeleted(selfCareId: string) {
    const result = await dispatchDeleteSelfCare(this.$store, {selfCareId});
    if (result) {
      this.selectedSelfCares = this.selectedSelfCares.filter((id) => id !== selfCareId);
    }
  }

  /**
   * deletes a selfCare from the list of selected selfCares
   */
  public selfCareRemoved(selfCareId: string) {
    this.selectedSelfCares = this.selectedSelfCares.filter((id) => id !== selfCareId);
  }

  /**
   * selfCare updated event handler
   * replace the selfCare in the list of selected selfCare
   */
  public async selfCareUpdated(selfCare: ISelfCare) {
    // find index of the selfCare and replace it into the same position
    const index = this.selectedSelfCares.findIndex((id) => id === selfCare.id);
    if (index !== -1) {
      this.selectedSelfCares.splice(index, 1, selfCare.id!);
    }
  }

  public editSelfCare(selfCare: ISelfCare) {
    this.$bus.$emit('selfcare:edit', selfCare);
  }

  /**
   * event handler for symptoms creation
   * creates a symptom and includes it into the list of
   * selected symptoms
   * @Args symptom: ISymptom
   */
  public async symptomCreated(symptom: ISymptom) {
    const result = await dispatchCreateSymptom(this.$store, symptom as ICreateSymptom);
    if (result && result.id) {
      this.selectedSymptoms.push(result.id);
    } else {
      // FIXME - important log here
    }
  }

  /**
   * Deletes a symptom from the Database
   * and from the list of selected symptoms
   */
  public async symptomDeleted(symptomId: string) {
    const result = await dispatchDeleteSymptom(this.$store, {symptomId});
    if (result) {
      this.selectedSymptoms = this.selectedSymptoms.filter((id) => id !== symptomId);
    }
  }

  /**
   * deletes a symptom from the list of selected symptoms
   */
  public symptomRemoved(symptomId: string) {
    this.selectedSymptoms = this.selectedSymptoms.filter((id) => id !== symptomId);
  }

  /**
   * symptom updated event handler
   * replace the symptom in the list of selected symptoms
   */
  public async symptomUpdated(symptom: ISymptom) {
    // find index of the cause and replace it into the same position
    const index = this.selectedSymptoms.findIndex((id) => id === symptom.id);
    if (index !== -1) {
      this.selectedSymptoms.splice(index, 1, symptom.id!);
    }
  }

  public editSymptom(symptom: ISymptom) {
    this.$bus.$emit('symptom:edit', symptom);
  }

  /**
   * event handler for causes creation
   * creates a cause and includes it into the list of
   * selected causes
   * @Args cause: ICause
   */
  public async causeCreated(cause: ICause) {
    const result = await dispatchCreateCause(this.$store, cause as ICreateCause);
    if (result && result.id) {
      this.selectedCauses.push(result.id);
    } else {
      // FIXME - important log here
    }
  }

  /**
   * deletes a cause from the list of selected causes
   */
  public causeRemoved(causeId: string) {
    this.selectedCauses = this.selectedCauses.filter((id) => id !== causeId);
  }

  /**
   * Deletes a cause from the Database
   * and from the list of selected causes
   */
  public async causeDeleted(causeId: string) {
    const result = await dispatchDeleteCause(this.$store, {causeId});
    if (result) {
      this.selectedCauses = this.selectedCauses.filter((id) => id !== causeId);
    }
  }

  /**
   * cause updated event handler
   * replace the cause in the list of selected causes
   */
  public async causeUpdated(cause: ICause) {
    // find index of the cause and replace it into the same position
    const index = this.selectedCauses.findIndex((id) => id === cause.id);
    if (index !== -1) {
      this.selectedCauses.splice(index, 1, cause.id!);
    }
  }

  public editCause(cause: ICause) {
    this.$bus.$emit('cause:edit', cause);
  }

  /**
   * event handler for execise creation
   * creates an exercise and includes it into the list of
   * selected exercises
   * @Args exercise: IExercise
   */
  public async exerciseCreated(exercise: ICreateExercise) {
    const result = await dispatchCreateExercise(this.$store, exercise);
    if (result && result.id) {
      this.selectedExercises.push(result.id);
    } else {
      // FIXME - important log here
    }
  }

  /**
   * Deletes an exercise from the Database
   * and from the list of selected exercises
   */
  public async exerciseDeleted(exerciseId: string) {
    const result = await dispatchDeleteExercise(this.$store, {exerciseId});
    if (result) {
      this.selectedExercises = this.selectedExercises.filter((id) => id !== exerciseId);
    }
  }

  /**
   * deletes an exercise from the list of selected exercises
   */
  public exerciseRemoved(exerciseId: string) {
    this.selectedExercises = this.selectedExercises.filter((id) => id !== exerciseId);
  }

  /**
   * exercise updated event handler
   * replace the exercise in the list of selected exercises
   */
  public async exerciseUpdated(exercise: IExercise) {
    // find index of the exercise and replace it into the same position
    const index = this.selectedExercises.findIndex((id) => id === exercise.id);
    if (index !== -1) {
      this.selectedExercises.splice(index, 1, exercise.id!);
    }
  }

  public editExercise(exercise: IExercise) {
    this.$bus.$emit('exercise:edit', exercise);
  }

  /**
   * event handler for supplement creation
   * creates an supplement and includes it into the list of
   * selected supplements
   * @Args supplement: ISupplement
   */
  public async supplementCreated(supplement: ICreateSupplement) {
    const result = await dispatchCreateSupplement(this.$store, supplement);
    if (result && result.id) {
      this.selectedSupplements.push(result.id);
    } else {
      // FIXME - important log here
    }
  }

  /**
   * Deletes an supplement from the Database
   * and from the list of selected supplements
   */
  public async supplementDeleted(supplementId: string) {
    const result = await dispatchDeleteSupplement(this.$store, {supplementId});
    if (result) {
      this.selectedSupplements = this.selectedSupplements.filter((id) => id !== supplementId);
    }
  }

  /**
   * deletes an supplement from the list of selected supplements
   */
  public supplementRemoved(supplementId: string) {
    this.selectedSupplements = this.selectedSupplements.filter((id) => id !== supplementId);
  }

  /**
   * supplement updated event handler
   * replace the supplement in the list of selected supplements
   */
  public async supplementUpdated(supplement: ISupplement) {
    // find index of the exercise and replace it into the same position
    const index = this.selectedSupplements.findIndex((id) => id === supplement.id);
    if (index !== -1) {
      this.selectedSupplements.splice(index, 1, supplement.id!);
    }
  }

  public editSupplement(supplement: ISupplement) {
    this.$bus.$emit('supplement:edit', supplement);
  }


  /**
   * event handler for food creation
   * creates an food and includes it into the list of
   * selected foods
   * @Args food: IFood
   * @Args type: 'eat' | 'avoid'
   */
  public async foodCreated(food: ICreateFood, type: 'eat' | 'avoid' = 'eat') {
    const result = await dispatchCreateFood(this.$store, food);
    if (result && result.id) {
      if (type === 'eat') {
        this.selectedFoods.push(result.id);
      } else {
        this.selectedFoodsToAvoid.push(result.id);
      }
    } else {
      // FIXME - important log here
    }
  }

  /**
   * Deletes an food from the Database
   * and from the list of selected foods
   */
  public async foodDeleted(foodId: string, type: 'eat' | 'avoid' = 'eat') {
    const result = await dispatchDeleteFood(this.$store, {foodId});
    if (result) {
      if (type === 'eat') {
        this.selectedFoods = this.selectedFoods.filter((id) => id !== foodId);
      } else {
        this.selectedFoodsToAvoid = this.selectedFoodsToAvoid.filter((id) => id !== foodId);
      }
    }
  }

  /**
   * deletes an food from the list of selected foods
   */
  public foodRemoved(foodId: string, type: 'eat' | 'avoid' = 'eat') {
    if (type === 'eat') {
      this.selectedFoods = this.selectedFoods.filter((id) => id !== foodId);
    } else {
      this.selectedFoodsToAvoid = this.selectedFoodsToAvoid.filter((id) => id !== foodId);
    }
  }

  /**
   * food updated event handler
   * replace the food in the list of selected foods
   * @Args type: 'eat' | 'avoid'
   */
  public async foodUpdated(food: IFood, type: 'eat' | 'avoid' = 'eat') {
    // find index of the exercise and replace it into the same position
    if (type === 'eat') {
      const index = this.selectedFoods.findIndex((id) => id === food.id);
      if (index !== -1) {
        this.selectedFoods.splice(index, 1, food.id!);
      }
    } else {
      const index = this.selectedFoodsToAvoid.findIndex((id) => id === food.id);
      if (index !== -1) {
        this.selectedFoodsToAvoid.splice(index, 1, food.id!);
      }
    }
  }

  public editFood(food: IFood) {
    this.$bus.$emit('food:edit', food);
  }

  public emitCreation(result) {
    this.$emit('recommendation:created', result);
  }

  public emitUpdate(result) {
    this.$emit('recommendation:updated', result);
  }

  public cancel() {
    this.$emit('recommendation:cancelled');
  }

  /**
   * Updates the current recommendation with the values
   * from the form.
   * Compares the old values with the new ones and updates only the
   * changed values
   */
  public async updateRecommendation() {
    const updatePayload = {} as IUpdateRecommendation;
    if (this.recommendation) {
      this.setFieldIfChanges(updatePayload, 'name', this.name, this.recommendation.name);
      this.setFieldIfChanges(updatePayload, 'biomarker', this.assignedBiomarker, this.recommendation.biomarkerId);
      this.setFieldIfChanges(updatePayload, 'sex', this.sex, this.recommendation.sex);
      this.setFieldIfChanges(updatePayload, 'foodsToAvoid',
        this.selectedFoodsToAvoid, this.extractIds(this.recommendation.foodsToAvoid || []));
      this.setFieldIfChanges(updatePayload, 'foodsToEat',
        this.selectedFoods, this.extractIds(this.recommendation.foodsToEat || []));
      this.setFieldIfChanges(updatePayload, 'exercises',
        this.selectedExercises, this.extractIds(this.recommendation.exercises || []));
      this.setFieldIfChanges(updatePayload, 'supplements',
        this.selectedSupplements, this.extractIds(this.recommendation.supplements || []));
      this.setFieldIfChanges(updatePayload, 'selfcares', this.selectedSelfCares, this.recommendation.selfcares);
      this.setFieldIfChanges(updatePayload, 'causes',
        this.selectedCauses, this.extractIds(this.recommendation.causes || []));
      this.setFieldIfChanges(updatePayload, 'symptoms',
        this.selectedSymptoms, this.extractIds(this.recommendation.symptoms || []));
      this.setFieldIfChanges(updatePayload, 'additionalTests', this.selectedAdditionalTests,
        this.extractIds(this.recommendation.additionalTests || []));
      this.setFieldIfChanges(updatePayload, 'summaryDescription',
        this.summaryDescription, this.recommendation.summaryDescription);
      this.setFieldIfChanges(updatePayload, 'causesDescription', this.causesDescription,
        this.recommendation.causesDescription);
      this.setFieldIfChanges(updatePayload, 'symptomsDescription',
        this.symptomsDescription, this.recommendation.symptomsDescription);
      this.setFieldIfChanges(updatePayload, 'foodsToEatDescription',
        this.foodsToEatDescription, this.recommendation.foodsToEatDescription);
      this.setFieldIfChanges(updatePayload, 'foodsToAvoidDescription',
        this.foodsToAvoidDescription, this.recommendation.foodsToAvoidDescription);
      this.setFieldIfChanges(updatePayload, 'supplementsDescription',
        this.supplementsDescription, this.recommendation.supplementsDescription);
      this.setFieldIfChanges(updatePayload, 'inRange',
        this.inRange, this.recommendation.inRange);
      this.setFieldIfChanges(updatePayload, 'outOfRangeType',
        this.outOfRangeType, this.recommendation.outOfRangeType);
      this.setFieldIfChanges(updatePayload, 'additionalTestsDescription',
        this.additionalTestsDescription, this.recommendation.additionalTestsDescription);
      this.setFieldIfChanges(updatePayload, 'selfCareDescription',
        this.selfCareDescription, this.recommendation.selfCareDescription);

      this.setFieldIfChanges(updatePayload, 'followUpsDescription',
        this.followUpsDescription, this.recommendation.followUpsDescription);

    } else {
      updatePayload.name = this.name;
      updatePayload.biomarkerId = this.assignedBiomarker;
      updatePayload.foodsToEat = this.selectedFoods;
      updatePayload.foodsToAvoid = this.selectedFoodsToAvoid;
      updatePayload.exercises = this.selectedExercises;
      updatePayload.supplements = this.selectedSupplements;
      updatePayload.causes = this.selectedCauses;
      updatePayload.sex = this.sex;
      updatePayload.symptoms = this.selectedSymptoms;
      updatePayload.summaryDescription = this.summaryDescription;
      updatePayload.causesDescription = this.causesDescription;
      updatePayload.symptomsDescription = this.symptomsDescription;
      updatePayload.foodsToEatDescription = this.foodsToEatDescription;
      updatePayload.foodsToAvoidDescription = this.foodsToAvoidDescription;
      updatePayload.supplementsDescription = this.supplementsDescription;
      updatePayload.additionalTestsDescription = this.additionalTestsDescription;
      updatePayload.selfCareDescription = this.selfCareDescription;
      updatePayload.selfcares = this.selectedSelfCares;
      updatePayload.followUpsDescription = this.followUpsDescription;
      updatePayload.inRange = this.inRange;
      updatePayload.outOfRangeType = this.outOfRangeType;
      updatePayload.additionalTests = this.selectedAdditionalTests;
    }
    if (Object.keys(updatePayload).length > 0) {
      const result = await dispatchUpdateRecommendation(this.$store, {
        recommendationId: this.recommendationId,
        recommendations: updatePayload,
      });
      if (result) {
        this.emitUpdate(result);
      } else {
        // FIXME - important log here
      }
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.isCreation) {
        const creationPayload: ICreateRecommendation = {
          name: this.name || '',
          biomarkerId: this.assignedBiomarker || this.biomarker.id!,
          foodsToAvoid: this.selectedFoodsToAvoid,
          sex: this.sex,
          foodsToEat: this.selectedFoods,
          exercises: this.selectedExercises,
          supplements: this.selectedSupplements,
          causes: this.selectedCauses,
          symptoms: this.selectedSymptoms,
          summaryDescription: this.summaryDescription,
          causesDescription: this.causesDescription,
          symptomsDescription: this.symptomsDescription,
          foodsToEatDescription: this.foodsToEatDescription,
          foodsToAvoidDescription: this.foodsToAvoidDescription,
          supplementsDescription: this.supplementsDescription,
          additionalTestsDescription: this.additionalTestsDescription,
          followUpsDescription: this.followUpsDescription,
          additionalTests: this.selectedAdditionalTests,
          selfcares: this.selectedSelfCares,
          selfCareDescription: this.selfCareDescription,
          outOfRangeType: this.outOfRangeType,
        };
        // remove empty values
        Object.keys(creationPayload).forEach((key) => {
          if (!creationPayload[key] || creationPayload[key] === '') {
            delete creationPayload[key];
          }
        });
        creationPayload.inRange = this.inRange;
        // send the payload to the parent component
        this.emitCreation(creationPayload);
      } else {
        this.updateRecommendation();
      }
    }
  }

}
